import { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Draggable from "react-draggable";
import colors from "../../../../utils/colors";

const DragAndDropQuestionComponent = ({ currentQuestionIndex, question }) => {
  const [heights, setHeights] = useState([]);
  const answerRefs = useRef([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const newHeights = answerRefs.current.map((ref) => ref?.offsetHeight || 0);
    setHeights(newHeights);
  }, [question]);

  return (
    <Box>
      <Box
        sx={{
          width: 400,
          height: 400,
          position: "relative", // Required for bounds to work
          overflow: "hidden", // Prevents draggable items from escaping
          border: "1px solid gray",
          borderRadius: 2,
        }}
        className="drag-and-drop"
      >
        {question.ANSWERS.map((answer, index) => (
          <Draggable
            allowAnyClick
            key={index}
            bounds=".drag-and-drop"
            grid={[15, 15]}
          >
            <Box
              ref={(el) => (answerRefs.current[index] = el)}
              sx={{
                position: "absolute", // Draggable requires position to be absolute
                borderLeft: "3px solid lightblue",
                borderRadius: 5,
                backgroundColor: colors.primary,
              }}
            >
              <Box
                sx={{
                  borderBottom: "2px solid lightblue",
                  padding: 1,
                  borderRadius: 5,
                  backgroundColor: colors.primary,
                  boxSizing: "border-box",
                }}
              >
                <Typography
                  variant="body2"
                  component="div"
                  sx={{ marginLeft: 2, marginBottom: 1 }}
                >
                  ANSWER: {answer.ANSWER_TEXT}
                </Typography>
                <Typography
                  variant="body2"
                  component="div"
                  sx={{ marginLeft: 2 }}
                >
                  EXPLANATION: {answer.EXPLANATION}
                </Typography>
              </Box>
            </Box>
          </Draggable>
        ))}
      </Box>
    </Box>
  );
};

export default DragAndDropQuestionComponent;
